//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkeletonWrapper from '~/components/skeleton/SkeletonWrapper'
import SkeletonRect from '~/components/skeleton/rect'

export default {
  name: 'TopHeader',
  components: {
    SkeletonWrapper,
    SkeletonRect,
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
    }, 35)
  },
}
