//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {},
  props: {
    loyaltyPoints: {
      type: Number,
      default: null,
    },
    loyaltyPointsDiscount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      message: ''
    }
  },
  computed: {
    areThereAnyLoyaltyPoints() {
      return this.$props.loyaltyPoints && this.$props.loyaltyPointsDiscount
    },
  },
  methods: {
    makeToast(variant = null) {
      this.$bvToast.toast(this.message, {
        autoHideDelay: 3000,
        variant,
        solid: true,
      })
    },
    async applyLoyaltyProgram() {
      try {
        await this.$api.cart.applyLoyaltyProgram()
        this.$emit('change')
      } catch (error) {
        this.message = error.response.data.message
        this.makeToast('danger')
        // console.log(error)
      }
    },
    async deleteLoyaltyProgram() {
      try {
        await this.$api.cart.deleteLoyaltyProgram()
        this.$emit('change')
      } catch (error) {
        // console.log(error)
      }
    },
  },
}
