var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('popover',{ref:"popover",class:[
      'header-popover',
      { 'header-popover--another': _vm.anotherPopover }
    ],attrs:{"name":_vm.icon},on:{"click:open":_vm.onOpenPopover,"click:close":_vm.onClosePopover},scopedSlots:_vm._u([{key:"inner",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)},[_c('span',{staticClass:"header-popover--btn",class:{ active: _vm.active }},[(_vm.total)?_c('div',{staticClass:"header-popover__info"},[_c('div',{staticClass:"header-popover__info--inner"},[_c('p',{staticClass:"header-popover__info-text"},[_vm._v("\n          "+_vm._s(_vm.total)+"\n        ")])])]):_vm._e(),_vm._v(" "),_vm._t("text"),_vm._v(" "),(_vm.childComponent)?[_c('icon-base',{class:[
          'icon',
          { 'icon--locale': _vm.icon === 'arrow',
          'icon--hidden': this.$i18n.locales.length === 1 && _vm.icon === 'arrow'
          }
        ],attrs:{"width":_vm.w,"height":_vm.h,"iconColor":_vm.isActive}},[_c(_vm.childComponent,{tag:"component"})],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }