//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {mapState} from "vuex";
import IconBase from '~/components/GlobalUI/IconBase.vue'
import IconMail from '~/components/icons/IconMail.vue'
import IconPhone from '~/components/icons/IconPhone.vue'

export default {
  components: {
    IconBase,
    IconMail,
    IconPhone,
  },

  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
  },
}

