//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import HeaderPopoverActions from './HeaderPopoverAction.vue'
import { HAS_TOKEN } from '~/store/auth/types.auth'
import { favorites } from '~/mock/favorites'
import { basket } from '~/mock/basket'
import { IS_DESKTOP, IS_LAPTOP } from '~/store/device/type.device'
import IconHeart from '~/components/icons/IconHeart.vue'
import IconCart from '~/components/icons/IconCart.vue'
import IconUser from '~/components/icons/IconUser.vue'
import IconInfo from '~/components/icons/IconInfo.vue'

export default {
  components: {
    HeaderPopoverActions,
    IconHeart,
    IconCart,
    IconUser,
    IconInfo,
    PopoverLocale: () =>
      import('~/components/modules/PopoverBlocks/PopoverLocale.vue'),
    PopoverDefault: () =>
      import('~/components/modules/PopoverBlocks/PopoverDefault.vue'),
    PopoverContacts: () =>
      import('~/components/modules/PopoverBlocks/PopoverContacts.vue'),
    PopoverUserMenu: () =>
      import('~/components/modules/PopoverBlocks/PopoverUserMenu.vue'),
    PopoverFavorites: () =>
      import('~/components/modules/PopoverBlocks/PopoverFavorites.vue'),
    PopoverBasket: () =>
      import('~/components/modules/PopoverBlocks/PopoverBasket.vue'),
  },
  data() {
    return {
      favorites,
      basket,
      IconHeart,
      IconCart,
      IconUser,
      IconInfo,
    }
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.settings,
      products: (state) => state.cart.items,
    }),
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    isLaptop() {
      return this.$store.getters[`device/${IS_LAPTOP}`]
    },
    isDesktop() {
      return this.$store.getters[`device/${IS_DESKTOP}`]
    },
    actions() {
      return [
        {
          icon: 'arrow',
          w: '20px',
          h: '20px',
          type: 'locale',
          signIn: true,
          anotherPopover: true,
          visible: this.isLaptop && this.settings.l10n,
          total: 0,
        },
        {
          icon: 'info',
          childComponent: this.IconInfo,
          w: '24px',
          h: '24px',
          signIn: true,
          visible: this.isLaptop || this.isDesktop,
          total: 0,
        },
        {
          popoverWidth: '380px',
          icon: 'user',
          childComponent: this.IconUser,
          w: '20px',
          h: '20px',
          text: this.$t('signInToManagingYouAccount'),
          signIn: this.isAuth,
          visible: this.isLaptop || this.isDesktop,
          total: 0,
        },
        {
          popoverWidth: '360px',
          icon: 'heart',
          childComponent: this.IconHeart,
          w: '24px',
          h: '24px',
          text: this.$t(
            'signInToSeeItemsYouMayHaveAddedToFavoritesUsingAnotherComputerOrDevice',
          ),
          signIn: this.isAuth,
          visible: true,
          total: 0,
        },
        {
          popoverWidth: '334px',
          icon: 'basket',
          childComponent: this.IconCart,
          w: '24px',
          h: '24px',
          text: this.$t(
            'signInToSeeItemsYouMayHaveAddedToCartUsingAnotherComputerOrDevice',
          ),
          signIn: true,
          visible: true,
          total: this.products.length,
        },
      ]
    },
  },
  methods: {
    onClosePopover(i) {
      const popover = this.$refs['header-popover'][i - 1]
      if (popover) {
        popover.$refs.popover.onClose()
      }
    },
    onClose() {
      const popovers = this.$refs['header-popover']
      for (const popover of popovers) {
        popover.$refs.popover.onClose()
      }
    },
  },
}
