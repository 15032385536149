//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GoUp from '~/components/UI/GoUp.vue'
import MediaFiles from '~/components/UI/MediaFiles.vue'
import { HAS_TOKEN } from '~/store/auth/types.auth'
export default {
  name: 'ProductMicroInfoMiniature',
  components: { GoUp, MediaFiles },
  props: {
    product: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  computed: {
    getEntity() {
      const type = /product/
      const patch = this.$route.path
      return this.$props.entity || (type.test(patch) ? 'product' : 'set')
    },
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    hasPrice() {
      if (!Object.keys(this.product).length) return false
      if (!this.isAuth || Array.isArray(this.product.price_type)) return false
      return !!Number(this.product.price_type.netto)
    },
    isAction() {
      if (!this.isAuth) {
        // return this.$t('signIn')
      } else if (!this.isAvailable) {
        return this.$t('notifyMeWhenAvailable')
      } else {
        return this.$t('addToCart')
      }
    },
    nameBtnProduct() {
      return !this.isAvailable
        ? this.$t('notifyMeWhenAvailable')
        : this.$t('addToCart')
    },
    isAvailable() {
      return this.product.status === 1
    },
    isFile() {
      return !!this.product.files[0]
    },
    isPrice() {
      if (!this.hasPrice) return 0
      else if (+this.product.price_type.discount_netto) {
        return this.product.price_type.discount_netto + ' zl'
      }
      return this.product.price_type.netto + ' zl'
    },
    productQty() {
      return this.$store.getters.getProductCount
    },
  },

  methods: {
    async addToCart(product) {
      const payload = {
        entity: this.getEntity,
        quantity: this.productQty || 1,
        slug: product.slug,
        ...this.getTempUid,
      }
      if (this.nameBtnProduct === this.$t('addToCart')) {
        try {
          await this.$api.cart.addItemCart(payload)
          // product.in_cart = true
          this.$store.commit('cart/SET_CART_PRODUCT', payload)
        } catch (error) {
          // console.log(error)
        }
      }

      if (this.nameBtnProduct === this.$t('notifyMeWhenAvailable')) {
        const params = {
          type: 1,
          entity: this.getEntity,
          code: product.code,
        }

        try {
          await this.$api.tickets.addTicket(params)
          this.textTicket = this.$t('yourRequestHasBeenSent')
        } catch (error) {}
      }
    },

    handleClick() {
      if (!this.isAuth) {
        this.$root.$emit('handleAuthModal', 'register')
      }
    },
  },
}
