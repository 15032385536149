//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { HAS_TOKEN } from '~/store/auth/types.auth'
import Popover from '~/components/UI/Popover.vue'
import IconBase from '~/components/GlobalUI/IconBase.vue'

export default {
  name: 'HeaderPopoverAction',
  components: {
    Popover,
    IconBase
  },
  props: {
    anotherPopover: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    childComponent: {
      type: Object
    },
    icon: {
      type: String,
      default: '',
      require: true,
    },
    w: {
      type: String,
      default: '20px',
    },
    h: {
      type: String,
      default: '20px',
    },
    stroke: {
      type: String,
      default: '#000000',
    },
    fill: {
      type: String,
      default: 'none',
    },
    fillActive: {
      type: String,
      default: '#000000',
    },
  },
  data() {
    return {
      active: false,
    }
  },
  computed: {
    isActive() {
      return !this.active ? this.fill : this.fillActive
    },
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },
  methods: {
    onOpenPopover() {
      this.$emit('click:close')
      this.active = true
    },
    onClosePopover() {
      this.active = false
    },
  },
}
