//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductPrice from '~/components/UI/ProductPrice.vue'
import Rating from '~/components/UI/Rating.vue'
import {HAS_TOKEN} from '~/store/auth/types.auth'

export default {
  name: 'ProductItemCard',
  components: {
    ProductPrice,
    Rating,
    MediaFiles: () => import('~/components/UI/MediaFiles.vue'),
    Labels: () => import('~/components/UI/Labels.vue'),
    ProductStatus: () => import('~/components/UI/ProductStatus.vue'),
    PicturesRowList: () => import('~/components/UI/PicturesRowList.vue'),
    ProductDescriptionsActions: () =>
      import('~/components/pages/Product/components/ProductDescriptionsActions.vue'),
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    entity: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => ({}),
      require: true,
    },
  },
  data() {
    return {
      selected: '',
      ticketText: '',
      styleStar: {
        size: 13,
        padding: 8,
      },
    }
  },
  computed: {
    getSelectedFile() {
      if (this.selected === 'object') {
        const url = this.selected && this.selected.files && this.selected.files[0] && this.selected.files[0].preview_url
        if (!url) return ''
      }
      return this.selected ? this.selected : ''
    },
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
    isAvailable() {
      return this.item.status === 1
    },
    hasPrice() {
      if (!this.isAuth || Array.isArray(this.item.price_type)) return false
      return !!Number(this.item && this.item.price_type && this.item.price_type.netto)
    },
    buttonType() {
      if (!this.isAvailable) return 'secondary'
      return 'primary'
    },
    price() {
      if (!this.isAuth || Array.isArray(this.item.price_type)) {
        return {
          price: false,
          discountPrice: false,
          discount: '0',
        }
      }
      const price = Number(this.item.price_type.netto)
      const discountPrice = Number(this.item.price_type.discount_netto)
      const discount = 100 - Math.ceil((discountPrice * 100) / price)
      return {
        price: `${price} zl`,
        discountPrice: discountPrice && `${discountPrice} zl`,
        discount: `-${discount} %`,
      }
    },
    icon() {
      return {
        icon: !this.item.price_type.netto || !this.item.price_type.brutto ? 'preorder'
          : !this.isAvailable
            ? 'mail'
            : 'add-to-basket',
        color: !this.isAvailable ? 'none' : '#ffffff',
        stroke: !this.isAvailable
          ? this.$color.dark
          : 'none',
        size: !this.isAvailable ? '24px' : '24px',
      }
    },
    link() {
      return (slug) => {
        return {
          name: `${this.type === 1 ? 'product' : 'set'}-id`,
          params: {id: slug},
        }
      }
    },
    nameBtnProduct() {
      if (!this.item.price_type.netto || !this.item.price_type.brutto) return this.$t('preorder');
      if (!this.isAvailable) return this.$t('notifyMeWhenAvailable')
      return this.$t('addToCart')
    },
    getEntity() {
      return this.$props.entity ? this.$props.entity : this.$props.type === 1 ? 'product' : 'set'
    },
  },
  watch: {
    ticketText() {
      setTimeout(() => {
        this.ticketText = ''
      }, 4000)
    },
    item() {
      this.selected = this.item.files[0]?.preview_url || ''
    },
  },
  mounted() {
    this.selected = this.item.files[0]?.preview_url || ''
  },
  methods: {
    variationHover(item) {
      this.selected = item.file
    },
    variationLeave() {
      this.selected = this.item.files[0]?.preview_url || ''
    },
    makeToast(variant = null) {
      this.$bvToast.toast(this.ticketText, {
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    async clickProductBtn() {
      //todo stas (cart)
      // console.log('stas')
      // return false;
      const form = {
        entity: this.getEntity,
        slug: this.$props.item.slug,
        ...this.getTempUid,
      }
      const product = {
        name: this.$props.item.name,
        picture: this.$props.item.files[0] && this.$props.item.files[0].preview_url,
        slug: this.$props.item.slug,
        price: this.$props.item.sum,
        qty: this.$props.item.quantity,
      }

      if (this.nameBtnProduct === this.$t('addToCart')) {
        try {
          await this.$api.cart.addItemCart(form)
          this.item.in_cart = true
          this.$store.commit('cart/SET_CART_PRODUCT', product)
        } catch (error) {
          this.ticketText = error.response.data.message;
          this.makeToast('danger');
        }
      }

      if (this.nameBtnProduct === this.$t('notifyMeWhenAvailable')) {
        const params = {
          type: 1,
          code: this.$props.item.code,
          ...form,
        }

        try {
          await this.$api.tickets.addTicket(params)
          this.ticketText = this.$t('yourRequestHasBeenSent')
          this.makeToast('success');
        } catch (error) {
          this.ticketText = error.response.data.message
          this.makeToast('danger');
          this.$root.$emit('handleAuthModal')
        }
      }


      if (this.nameBtnProduct === this.$t('preorder')) {
        const params = {
          type: 6,
          code: this.$props.item.code,
          ...form,
        }

        try {
          await this.$api.tickets.addTicket(params)
          this.ticketText = this.$t('yourRequestHasBeenSent')
          this.makeToast('success');
        } catch (error) {
          this.ticketText = error.response.data.message
          this.makeToast('danger');
        }
      }
    },
    async clickRequestThePrice() {
      const params = {
        type: 2,
        entity: this.getEntity,
        slug: this.$props.item.slug,
        code: this.$props.item.code,
      }

      try {
        await this.$api.tickets.addTicket(params)
      } catch (error) {
        this.ticketText = error.response.data.message
        this.makeToast('danger');
      }
    },
    onHover(type) {
      if (type === 'hover') {
        this.item.files[1] && (this.selected = this.item.files[1].preview_url)
        return
      }
      this.selected = this.item.files[0]?.preview_url || ''
    },
    onChangeProduct(value) {
      this.$request.$get(`/product/${value.slug}`).then(({data}) => {
        const payload = {
          id: this.item.id,
          data,
        }
        this.$emit('click', payload)
        this.selected = data.files[0]?.preview_url || ''
      })
    },
  },
}
